import { Controller } from "@hotwired/stimulus"
import datepicker from 'js-datepicker'

export default class extends Controller {
  static targets = ["dob"]

  connect() {
    const picker = datepicker(`#${this.dobTarget.id}`, { maxDate: new Date() })
  }
}
